import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { CREATE_ORDER_MUTATION } from '../../api/queries/OrderQueries';
import GlobalShortcut from '../GlobalShortcut';
import ToolbarButton from '../toolbar/ToolbarButton';
import { isWithinMenu } from '../common/Menu';
import VisibilityIcon from '../icons/VisibilityIcon';
import IndividualIcon from '../icons/IndividualIcon';
import GroupOnlyLabelRenderer from '../grid/renderers/GroupOnlyLabelRenderer';
import IndividualLabelRenderer from '../grid/renderers/IndividualLabelRenderer';
import MixpanelLogger from '../../diagnostics/logging/mixpanelLogger';
import { KEY_CODES } from 'constant';
import store from 'store';
import { gasDatasetIds } from '../../../_legacy/models/Datasets';

const PUBLIC_ORDER_VALUE = 1;
const PRIVATE_ORDER_VALUE = 2;
const GROUP_ONLY_ORDER_VALUE = 3;
const INDIVIDUAL_ORDER_VALUE = 4;

const { KEY_O } = KEY_CODES;

export class AddOrder extends Component {
    constructor(props) {
        super(props);

        // State is used so that the value of the dropdown can be reset
        // each time a value is picked, otherwise the control doesn't always work
        this.state = { dropdownValue: -1 };

        this.handleAddOrder = this.handleAddOrder.bind(this);
        this.isAddOrderKeyPress = this.isAddOrderKeyPress.bind(this);
        this.handleCompleted = this.handleCompleted.bind(this);
    }

    handleAddOrder = (addOrder, groupOnly, privacy, isIndividual) => {
        const { groupId } = this.props;
        const type =
            this.props.selectedLayout.commonSettings.defaultType.currentState;

        const variables = {
            groupId,
            groupOnly,
            privacy,
            isIndividual,
            type,
        };

        addOrder({
            variables,
        });
    };

    isAddOrderKeyPress = (event) => {
        if (event.altKey && event.ctrlKey && event.keyCode === KEY_O) {
            event.preventDefault();
            event.stopPropagation();
            return true;
        }
        return false;
    };

    handleCompleted(data) {
        const { id, groupOnly, privacy, groupName, isIndividual, datasetId } =
            data.createOrder;

        this.setState({ dropdownValue: -1 }, () => {
            if (this.props.onCompleted) {
                this.props.onCompleted(data.createOrder);
            }
        });

        MixpanelLogger.trackNewOrderEvent(
            id,
            groupOnly,
            privacy,
            isIndividual,
            groupName,
            'Created',
            datasetId
        );
    }

    getOptions = () => {
        const userDatasetId = store.getState().user.group.datasetId;

        const defaultOptions = [
            { key: 1, text: 'Public Order', value: PUBLIC_ORDER_VALUE },
            { key: 2, text: 'Private Order', value: PRIVATE_ORDER_VALUE },
            {
                key: 3,
                text: (
                    <GroupOnlyLabelRenderer
                        {...this.props}
                        documentName={'Order'}
                    />
                ),
                value: GROUP_ONLY_ORDER_VALUE,
                icon: <VisibilityIcon />,
            },
        ];

        if (gasDatasetIds.includes(userDatasetId)) {
            return defaultOptions;
        }

        return [
            ...defaultOptions,
            {
                key: 4,
                text: (
                    <IndividualLabelRenderer
                        {...this.props}
                        documentName={'Order'}
                    />
                ),
                value: INDIVIDUAL_ORDER_VALUE,
                icon: <IndividualIcon />,
            },
        ];
    };

    render = () => {
        return (
            <Mutation
                mutation={CREATE_ORDER_MUTATION}
                onCompleted={this.handleCompleted}
                fetchPolicy={'no-cache'}
            >
                {(addOrder, { loading }) => {
                    return (
                        <Fragment>
                            {!this.props.disabled && !loading && (
                                <GlobalShortcut
                                    onMatch={() =>
                                        this.handleAddOrder(
                                            addOrder,
                                            false,
                                            false,
                                            false
                                        )
                                    }
                                    keyMatcher={this.isAddOrderKeyPress}
                                />
                            )}
                            <Dropdown
                                trigger={
                                    <ToolbarButton id="NewOrderButton">
                                        <i className="icons plus-icon" />
                                        New Order
                                    </ToolbarButton>
                                }
                                options={this.getOptions(this.state)}
                                onChange={(e, data) => {
                                    /*
                                     * This ensures the user has actually clicked on a menu item as the dropdown onChange
                                     * gets triggered by a blur event. Defect 25602
                                     * Same logic used in AddFixture.jsx
                                     */
                                    if (
                                        isWithinMenu(e.target) ||
                                        e.key === 'Enter'
                                    ) {
                                        const groupOnly =
                                            data.value ===
                                            GROUP_ONLY_ORDER_VALUE;
                                        const isIndividual =
                                            data.value ===
                                            INDIVIDUAL_ORDER_VALUE;
                                        const privacy =
                                            data.value ===
                                                PRIVATE_ORDER_VALUE ||
                                            groupOnly ||
                                            isIndividual;

                                        this.handleAddOrder(
                                            addOrder,
                                            groupOnly,
                                            privacy,
                                            isIndividual
                                        );
                                    }
                                }}
                                value={this.state.dropdownValue}
                                disabled={this.props.disabled || loading}
                            />
                        </Fragment>
                    );
                }}
            </Mutation>
        );
    };
}

AddOrder.propTypes = {
    onCompleted: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        currentGroup: state.user && state.user.group ? state.user.group : null,
        selectedLayout: state.layouts.selectedLayout,
    };
};

export default connect(mapStateToProps)(AddOrder);
