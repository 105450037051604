import { RichText } from 'exceljs';

export const getDisclaimerHtml = (): string => {
    const currentYear = new Date().getFullYear();
    return `
        <p>© ${currentYear} by Clarksons. All rights reserved.</p>
        <p>The material and the information (including, without limitation, any future rates and/or forward-looking predictions) and any extract or portion thereof contained herein (together, the “<strong>Information</strong>”) are provided by Clarkson PLC or its relevant direct or indirect subsidiary (“<strong>Clarksons</strong>”) for the recipient’s internal use and for purpose of the recipient’s general guidance only. The Information is provided on an “as is” and “as available” basis. Clarksons and all its Group companies make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Information and shall not be subject to any damages or liability, including but not limited to any indirect, special, incidental, punitive or consequential damages. Any reliance placed on such Information is therefore strictly at the recipient’s own risk and no responsibility is accepted for any loss or damage howsoever arising. Please note that the Information is for illustration purposes only and given without guarantee.</p>
        <p>This Information is not for reproduction or distribution or the creation of any derivative works by the recipient without Clarksons’ prior written consent. Especially, the Information is not to be used for the purposes of entering into any fixtures or other commercial agreements, making any investment, trading, risk management decision or for the purpose of raising finance whether by way of debt or equity without Clarksons’ prior written consent. All intellectual property rights are fully reserved by Clarksons, its Group companies, and/or its licensors. Without prejudice to any other rights, any use or distribution of the Information beyond the express uses authorized in this paragraph above is subject to the payment of additional fees to Clarksons.</p>
        <p>This disclaimer shall be governed and construed in accordance with English law.</p>
    `;
};

export const getDisclaimerRichText = (): RichText[] => {
    const currentYear = new Date().getFullYear();
    return [
        {
            text: `© ${currentYear} by Clarksons. All rights reserved.\n\nThe material and the information (including, without limitation, any future rates and/or forward-looking predictions) and any extract or portion thereof contained herein (together, the “`,
            font: {
                bold: false,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: 'Information',
            font: {
                bold: true,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: '”) are provided by Clarkson PLC or its relevant direct or indirect subsidiary (“',
            font: {
                bold: false,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: 'Clarksons',
            font: {
                bold: true,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: '”) for the recipient’s internal use and for purpose of the recipient’s general guidance only. The Information is provided on an “as is” and “as available” basis. Clarksons and all its Group companies make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Information and shall not be subject to any damages or liability, including but not limited to any indirect, special, incidental, punitive or consequential damages. Any reliance placed on such Information is therefore strictly at the recipient’s own risk and no responsibility is accepted for any loss or damage howsoever arising. Please note that the Information is for illustration purposes only and given without guarantee.',
            font: {
                bold: false,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: '\n\n',
            font: {
                bold: false,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: 'This Information is not for reproduction or distribution or the creation of any derivative works by the recipient without Clarksons’ prior written consent. Especially, the Information is not to be used for the purposes of entering into any fixtures or other commercial agreements, making any investment, trading, risk management decision or for the purpose of raising finance whether by way of debt or equity without Clarksons’ prior written consent. All intellectual property rights are fully reserved by Clarksons, its Group companies, and/or its licensors. Without prejudice to any other rights, any use or distribution of the Information beyond the express uses authorized in this paragraph above is subject to the payment of additional fees to Clarksons.',
            font: {
                bold: false,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: '\n\n',
            font: {
                bold: false,
                color: {
                    argb: '000000',
                },
            },
        },
        {
            text: 'This disclaimer shall be governed and construed in accordance with English law.',
            font: {
                bold: false,
                color: {
                    argb: '000000',
                },
            },
        },
    ];
};
