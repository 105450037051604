import { isEditableForTbnRow } from '../tools/headingTools';
import DwtEditor from '../editors/DwtEditor';
import VesselDwtFormatter from '../formatters/VesselDwtFormatter';
import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import NamePropertyComparator from '../../../../components/grid/comparators/NamePropertyComparator';
import defaultObjEquals from '../../../../components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '../../../../components/grid/columnDefaults/defaultSetFilterParams';

export const vesselGasCbmHeading = {
    colId: 'gasCbm',
    headerName: 'CBM',
    field: 'vessel.gasCbm',
    editable: isEditableForTbnRow,
    cellEditorFramework: DwtEditor,
    valueFormatter: VesselDwtFormatter,
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    filter: 'deadweightFilter',
    filterParams: {
        ...defaultSetFilterParams,
    },
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    width: 120,
};
