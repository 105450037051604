import React, { Component } from 'react';
import { FilterGenerator } from './FilterGenerator';
import store from 'store';
import { gasDatasetIds } from '../../../../_legacy/models/Datasets';

class VisibilityFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            includePublic: true,
            includePrivate: true,
            includeIndividual: true,
        };
    }

    isFilterActive() {
        return (
            !this.state.includePublic ||
            !this.state.includePrivate ||
            !this.state.includeIndividual
        );
    }

    doesFilterPass(params) {
        const entity = params.node.data;

        let includeAsPublic = false;
        let includeAsPrivate = false;
        let includeAsIndividual = false;

        if (this.state.includePublic) {
            includeAsPublic = entity.privacy === false;
        }

        if (this.state.includePrivate) {
            includeAsPrivate =
                entity.privacy === true && entity.isIndividual === false;
        }

        if (this.state.includeIndividual) {
            includeAsIndividual = entity.isIndividual === true;
        }

        return includeAsPublic || includeAsPrivate || includeAsIndividual;
    }

    getModel() {
        if (this.isFilterActive()) {
            return JSON.stringify({
                includePublic: this.state.includePublic,
                includePrivate: this.state.includePrivate,
                includeIndividual: this.state.includeIndividual,
            });
        } else {
            return null;
        }
    }

    setModel(model) {
        if (model) {
            const configuration = JSON.parse(model);

            this.setState(
                {
                    includePublic: configuration.includePublic,
                    includePrivate: configuration.includePrivate,
                    includeIndividual: configuration.includeIndividual,
                },
                () => this.props.filterChangedCallback()
            );
        } else {
            this.clearFilter();
        }
    }

    clearFilter = () => {
        this.setState(
            {
                includePublic: true,
                includePrivate: true,
                includeIndividual: true,
            },
            () => this.props.filterChangedCallback()
        );
    };

    handlePublicChange = (key, checked) => {
        this.setState({ includePublic: checked }, () =>
            this.props.filterChangedCallback()
        );
    };

    handlePrivateChange = (key, checked) => {
        this.setState({ includePrivate: checked }, () =>
            this.props.filterChangedCallback()
        );
    };

    handleIndividualChange = (key, checked) => {
        this.setState({ includeIndividual: checked }, () =>
            this.props.filterChangedCallback()
        );
    };

    render() {
        const getElements = () => {
            const userDatasetId = store.getState().user.group.datasetId;
            const elements = [
                [
                    {
                        type: 'checkbox',
                        checked: this.state.includePublic,
                        onChange: this.handlePublicChange,
                        label: 'PUBLIC',
                    },
                    {
                        type: 'checkbox',
                        checked: this.state.includePrivate,
                        onChange: this.handlePrivateChange,
                        label: 'PRIVATE',
                    },
                ],
            ];

            if (gasDatasetIds.includes(userDatasetId)) {
                return elements;
            }

            elements[0].push({
                type: 'checkbox',
                checked: this.state.includeIndividual,
                onChange: this.handleIndividualChange,
                label: 'INDIVIDUAL',
            });

            return elements;
        };

        return (
            <FilterGenerator
                elements={getElements()}
                clear={this.clearFilter}
            />
        );
    }
}

export default VisibilityFilter;
