import { GridApi } from '@ag-grid-enterprise/all-modules';
import { cargoQuantityColumnId } from '../headings/cargoQuantityPartsHeading';
import CargoQuantityPartsKeyCreator from '../keyCreators/CargoQuantityPartsKeyCreator';

//checks any new value in the set filter if it passes from-to filter
export const refreshCargoQuantityFilter = (params, gridApi: GridApi) => {
    const filter = gridApi.getFilterInstance(cargoQuantityColumnId) as any;
    if (filter?.filters) {
        const quantityFilter = filter.getChildFilterInstance(0);
        const setFilter = filter.getChildFilterInstance(1);
        if (quantityFilter?.componentInstance?.doesFilterPass(params)) {
            const value = CargoQuantityPartsKeyCreator(params);
            setFilter.valueModel.selectedValues.add(value);
        }
        setFilter.refreshFilterValues();
    }
};
