import { Column, RowNode } from '@ag-grid-enterprise/all-modules';
import ClarksonsLogo from '../components/images/ClarksonsLogo';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import { FIXTURE_GRID_TYPE } from '_legacy/constants/gridTypes';
import getHeadingTitle from '_legacy/services/headingsServices/getHeadingTitle';
import { createDataForExport } from '_legacy/excelExport/services/DataPreparationService';
import { getFormattedValue } from '_legacy/services/FormattedGridValueService';
import colors from './colors';
import emailStyleRules, { IStyleRule } from './EmailStyleRules';
import { getStyleRules } from '_legacy/excelExport/services/StyleSettingsService';

const css = `html, body { text-size-adjust: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-family: Calibri; font-size: 10.0pt; font-weight: 400; } table, tr, td { border: 0; border-collapse: collapse; }`;

interface IDataNode extends RowNode {
    dataRowsCount?: number;
    childNodes?: IDataNode[];
}

interface IEmailReportImage {
    id: string;
    content: string;
    type: string;
}

interface IEmailReportBuilderParams {
    ordersReportData: IEmailReportData;
    fixturesReportData: IEmailReportData;
}

interface IEmailReportData {
    context: any;
    nodes: RowNode[];
    columns: Column[];
    reportTitle: string;
    layoutName: string;
    datasetType: string;
}

type ExportType = 'Orders' | 'Fixtures' | 'Both';

export interface IEmailReport {
    content: string;
    subject: string;
    images: IEmailReportImage[];
}

export const buildEmailReport = (
    params: IEmailReportBuilderParams,
    reportTitle: string
) => {
    const { ordersReportData, fixturesReportData } = params;
    const exportType = getExportType(params);
    return buildEmail(
        ordersReportData,
        fixturesReportData,
        exportType,
        reportTitle
    );
};

const getExportType = (params: IEmailReportBuilderParams): ExportType => {
    if (params.ordersReportData) {
        return params.fixturesReportData ? 'Both' : 'Orders';
    } else {
        return 'Fixtures';
    }
};

const buildEmail = (
    ordersReportData: IEmailReportData,
    fixturesReportData: IEmailReportData,
    exportType: ExportType,
    reportTitle: string
): IEmailReport => {
    const currentDate = new Date();
    const image: IEmailReportImage = {
        id: 'Report.Header',
        type: 'image/png',
        content: ClarksonsLogo,
    };

    const reportContent = generateEmailContent(
        reportTitle,
        currentDate,
        ordersReportData,
        fixturesReportData,
        exportType
    );
    const html = ReactDOMServer.renderToStaticMarkup(reportContent);
    const report: IEmailReport = {
        content: html,
        subject: `CLARKSONS PLATOU - ${reportTitle.toUpperCase()} ${renderTitleDate(
            currentDate
        )}`,
        images: [image],
    };

    return report;
};

const generateEmailContent = (
    reportTitle: string,
    currentDate: Date,
    ordersReportData: IEmailReportData,
    fixturesReportData: IEmailReportData,
    exportType: ExportType
) => {
    let ordersRootNode;
    let fixturesRootNode;
    let firstRootNode;
    switch (exportType) {
        case 'Orders':
            ordersRootNode = getReportTree(ordersReportData);
            firstRootNode = ordersRootNode;
            break;
        case 'Fixtures':
            fixturesRootNode = getReportTree(fixturesReportData);
            firstRootNode = fixturesRootNode;
            break;
        case 'Both':
            ordersRootNode = getReportTree(ordersReportData);
            fixturesRootNode = getReportTree(fixturesReportData);
            firstRootNode = fixturesRootNode;
            break;
    }
    const { columns, datasetType, layoutName } =
        exportType === 'Orders' ? ordersReportData : fixturesReportData;
    const levelsCount = firstRootNode?.levelsCount ?? 0;

    return (
        <html lang="en">
            <head>
                <meta
                    httpEquiv="Content-Type"
                    content="text/html; charset=utf-8"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>{reportTitle}</title>
                <style type="text/css">{css}</style>
            </head>
            <body
                style={{
                    width: '100%',
                    margin: 0,
                    padding: 0,
                }}
            >
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td
                                align="left"
                                style={{
                                    paddingLeft: '40px',
                                }}
                            >
                                <img
                                    alt="Report Header"
                                    src="cid:Report.Header"
                                    width="342"
                                    style={{
                                        display: 'block',
                                        outline: 'none',
                                        border: 'none',
                                        textDecoration: 'none',
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    fontWeight: 800,
                                }}
                                colSpan={columns.length + levelsCount + 1}
                                align="right"
                            >
                                {datasetType}
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    fontWeight: 800,
                                    paddingLeft: levelsCount
                                        ? `${20 * levelsCount}px`
                                        : undefined,
                                }}
                            >
                                {layoutName}
                            </td>
                            <td
                                style={{
                                    fontWeight: 800,
                                }}
                                colSpan={columns.length + levelsCount}
                                align="right"
                            >
                                {moment(currentDate).format('DD/MM/YYYY')}
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
                {(exportType === 'Orders' || exportType === 'Both') &&
                    insertDataTable(
                        ordersReportData.columns,
                        ordersReportData.context,
                        ordersRootNode?.levelsCount,
                        ordersRootNode
                            ? ordersRootNode.childNodes
                            : ordersReportData.nodes,
                        exportType === 'Both' ? 'ORDERS' : undefined
                    )}
                {(exportType === 'Fixtures' || exportType === 'Both') &&
                    insertDataTable(
                        fixturesReportData.columns,
                        fixturesReportData.context,
                        fixturesRootNode?.levelsCount,
                        fixturesRootNode
                            ? fixturesRootNode.childNodes
                            : fixturesReportData.nodes,
                        exportType === 'Both' ? 'FIXTURES' : undefined
                    )}
            </body>
        </html>
    );
};

const getReportTree = (reportData: IEmailReportData) => {
    const treeStructuredNodes = createDataForExport(reportData.nodes);
    return treeStructuredNodes[0];
};

const insertDataTable = (
    columns: Column[],
    context: any,
    levelsCount = 0,
    nodes: RowNode[],
    title?: string
) => {
    const reportColumns = columns;
    return (
        <>
            {title && (
                <span
                    style={{
                        textDecoration: 'underline',
                        fontWeight: 800,
                    }}
                >
                    {title}
                </span>
            )}
            <table width="100%" cellPadding="0" cellSpacing="0">
                <tbody>
                    {insertColumnsRow(reportColumns, context, levelsCount)}
                    {insertRows(context, nodes, reportColumns, levelsCount)}
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

const insertRows = (
    context,
    nodes: IDataNode[],
    columns: Column[],
    levelsCount = 0
) => {
    return nodes.map((node) => {
        return node.group && node.childNodes ? (
            <>
                {insertGroupHeadingRow(columns, node, levelsCount)}
                {insertRows(context, node.childNodes, columns, levelsCount)}
            </>
        ) : (
            insertDataRow(context, columns, node, levelsCount)
        );
    });
};

const insertGroupHeadingRow = (
    columns: Column[],
    node: IDataNode,
    levelsCount: number = 0
) => {
    return (
        <tr>
            {[...Array(node.level)].map((x, i) => (
                <td
                    style={{
                        ...fillerCellStyle,
                        backgroundColor: colors.darkblue,
                    }}
                ></td>
            ))}
            <td
                colSpan={columns.length + levelsCount - node.level}
                style={{
                    backgroundColor: colors.darkblue,
                    color: colors.white,
                }}
            >{`${node.key} (${node.dataRowsCount})`}</td>
        </tr>
    );
};

const insertColumnsRow = (
    columns: Column[],
    context,
    levelsCount: number = 0
) => {
    const isFixtureGridType = context.gridId === FIXTURE_GRID_TYPE;
    return (
        <tr>
            {[...Array(levelsCount)].map((x, i) => (
                <td
                    style={{
                        ...fillerCellStyle,
                        backgroundColor: colors.grey4,
                    }}
                ></td>
            ))}
            {columns.map((column) => {
                const header = getHeadingTitle(
                    context.datasetId,
                    column.getColId(),
                    isFixtureGridType
                );
                return (
                    <td
                        style={{
                            width: column.getActualWidth(),
                            backgroundColor: colors.grey4,
                        }}
                    >
                        {header}
                    </td>
                );
            })}
        </tr>
    );
};

const insertDataRow = (
    context,
    columns: Column[],
    node: IDataNode,
    levelsCount: number = 0
) => {
    return (
        <tr>
            {[...Array(levelsCount)].map((x, i) => (
                <td style={fillerCellStyle}></td>
            ))}
            {columns.map((column) => {
                const colDef = column.getColDef();
                const value = getFormattedValue(colDef, node, context);
                const styleSetting = getDataColumnStyleSettings(
                    colDef,
                    node,
                    context
                );
                return (
                    <td
                        style={{
                            width: column.getActualWidth(),
                            border: '1px solid',
                            borderColor: colors.grey6,
                            ...styleSetting,
                        }}
                    >
                        {value}
                    </td>
                );
            })}
        </tr>
    );
};

const fillerCellStyle = {
    minWidth: '20px',
    width: '20px',
    maxWidth: '20px',
};

const renderTitleDate = (currentDate) =>
    moment(currentDate).format('DD MMM YY').toUpperCase();

export const getDataColumnStyleSettings = (
    colDef,
    node,
    context
): IStyleRule => {
    const rulesToApply = getStyleRules(colDef, node, context);
    const rules = emailStyleRules.filter(
        (style) => rulesToApply.filter((rule) => style.id === rule).length > 0
    );
    if (rules.length === 1) {
        return rules[0];
    } else {
        return Object.assign({}, ...rules);
    }
};
