import { getDataset } from '../models/Datasets';
import { DEFAULT_CARGO_ORIGINAL_NAME } from '../constants/default.values';
import { brokerManaged, unknown } from '../constants/channelValues';
import {
    balticIndexValue,
    flatRateValue,
    isUnsupportedUnitForType,
} from '_legacy/modules/columns/rate';
import { isUnsupportedBoundForType } from '_legacy/modules/columns/rate/models/RateBounds';
import moment from 'moment';

export const updateFreightPortBasisIfRequired = (
    data,
    variables,
    mutationValue,
    relatedFieldName,
    gridType
) => {
    if (
        (!data['freightPortBasis'] ||
            data['freightPortBasis'].shouldBeUpdatedAutomatically) &&
        data[relatedFieldName] &&
        data[relatedFieldName].length > 0
    ) {
        const mutationFieldValue =
            mutationValue && mutationValue.length > 0
                ? mutationValue.filter((p) => p.value === '+').length + 1
                : 0;

        const relatedFieldValue =
            data[relatedFieldName].filter((p) => p.value === '+').length + 1;

        const part =
            relatedFieldName === 'dischargeLocationParts'
                ? {
                      loadCount: mutationFieldValue,
                      dischargeCount: relatedFieldValue,
                  }
                : {
                      loadCount: relatedFieldValue,
                      dischargeCount: mutationFieldValue,
                  };

        const freightPortBasis = {};
        freightPortBasis.shouldBeUpdatedAutomatically = true;
        freightPortBasis.parts =
            mutationFieldValue === 0 || relatedFieldValue === 0 ? null : [part];

        data['freightPortBasis'] = freightPortBasis;
        variables[gridType]['freightPortBasis'] = freightPortBasis;
    }
};

export const updateCargoIfRequired = (
    data,
    variables,
    updatedType,
    gridType
) => {
    const shouldResetCargoIfPeriodType = getDataset(
        data['datasetId']
    ).shouldResetCargoIfPeriodType;

    const cargoParts = data['cargoParts'];
    const isCargoPartsValueDefault =
        cargoParts &&
        cargoParts.length === 1 &&
        cargoParts[0].originalName === DEFAULT_CARGO_ORIGINAL_NAME;

    if (
        shouldResetCargoIfPeriodType &&
        updatedType === 'PERIOD' &&
        isCargoPartsValueDefault
    ) {
        data['cargoParts'] = [];
        variables[gridType]['cargoParts'] = [];
    }
};

export const updateChannel = (data, variables, newBrokers, gridType) => {
    const channelValue =
        newBrokers && newBrokers.length !== 0 ? brokerManaged : unknown;
    data['channel'] = channelValue;
    variables[gridType]['channel'] = channelValue;
};

export const cleanRatesIfRequired = (
    data,
    variables,
    updatedType,
    gridType
) => {
    const rates = data['rates'];

    if (
        rates &&
        rates.rateParts &&
        updatedType !== 'PERIOD' &&
        updatedType !== 'SALE'
    ) {
        const shouldRatesBeCleared = rates.rateParts.some((part) => {
            if (!part.rateValue) {
                return false;
            }

            const isBalticIndexValue = part.rateValue.type === balticIndexValue;
            const isDurationRate = part.rateValue.duration;

            const isUnsupportedUnitOrBound =
                isUnsupportedUnitForType(part, updatedType) ||
                isUnsupportedBoundForType(part, updatedType);

            const isUnsupportedFlatRate =
                part.rateValue.type === flatRateValue &&
                isUnsupportedUnitOrBound;

            return (
                isBalticIndexValue || isDurationRate || isUnsupportedFlatRate
            );
        });

        if (shouldRatesBeCleared) {
            data['rates'] = null;
            variables[gridType]['rates'] = null;
        }
    }
};

export const clearDeliveryTermIfRequried = (
    data,
    variables,
    updatedType,
    gridType
) => {
    if (updatedType === 'VOY' || updatedType === 'COA') {
        data['deliveryTerm'] = null;
        variables[gridType]['deliveryTerm'] = null;
    }
};

export const updateWeek = (cpDate, data, variables, gridType) => {
    const week = cpDate ? getCountingWeek(cpDate) : null;
    data['week'] = week;
    variables[gridType]['week'] = week;
};

const getCountingWeek = (date) => {
    const d = moment(date);
    const year = d.year();

    // Day of the year for the current date
    const dayOfYear = d.dayOfYear();

    // January 1 of the same year
    const startOfYear = d.clone().startOf('year');

    // What day of the week is January 1?
    const dayOfWeekJan1 = startOfYear.day();

    // If January 1 is already Monday, we can just do a simple calculation:
    //   week = floor((dayOfYear - 1) / 7) + 1
    // because each Monday to Sunday is a full week
    if (dayOfWeekJan1 === 1) {
        const week = Math.floor((dayOfYear - 1) / 7) + 1;
        return `Wk${week} - ${year % 100}`;
    }

    // Otherwise, find the dayOfYear for the *first Monday* on or after January 1.
    // For example, if Jan 1 is Wednesday (dayOfWeekJan1 = 3),
    // then we want the upcoming Monday (dayOfWeek = 1).
    // The expression below gives the offset from Jan 1 to that Monday in days:
    //   (8 - dayOfWeekJan1) % 7
    const firstMonday = 1 + ((8 - dayOfWeekJan1) % 7);

    // If our date is still before that first Monday, it’s in "Week 1" (the partial week).
    if (dayOfYear < firstMonday) {
        return `Wk1 - ${year % 100}`;
    }

    // Otherwise, count how many full 7-day blocks have passed since that first Monday.
    // That first Monday is labeled "Week 2" (because Jan 1 was "Week 1").
    const diff = dayOfYear - firstMonday; // days since the first Monday
    const week = Math.floor(diff / 7) + 2;

    return `Wk${week} - ${year % 100}`;
};
