import TooltipRenderer from '_legacy/components/grid/renderers/TooltipRenderer';
import BlanksFilterRenderer from '_legacy/components/grid/renderers/BlanksFilterRenderer';
import suppressOnEdit from '_legacy/components/grid/columnDefaults/suppressOnEdit';
import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';

import CargoQuantityPartFilterComparator from '../comparators/CargoQuantityPartFilterComparator';
import { CargoQuantityPartsComparator } from '../comparators/CargoQuantityPartsComparator';
import CargoQuantityEditor from '../editors/CargoQuantityEditor';
import { MultipleCargoQuantityFormatter } from '../formatters/MultipleCargoQuantityFormatter';
import CargoQuantityPartsKeyCreator from '../keyCreators/CargoQuantityPartsKeyCreator';
import { isEditableRow } from '../../../shared/columns/tools/headingTools';

export const cargoQuantityColumnId = 'cargoQuantity';

export const cargoQuantityPartsHeading = {
    colId: cargoQuantityColumnId,
    headerName: 'Qty',
    field: 'cargoQuantityParts',
    enableRowGroup: true,
    editable: isEditableRow,
    valueFormatter: (value) => {
        return MultipleCargoQuantityFormatter(value, true);
    },
    cellEditorFramework: CargoQuantityEditor,
    cellRenderer: (quantity) =>
        TooltipRenderer({ value: quantity.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    equals: defaultObjEquals,
    comparator: CargoQuantityPartsComparator,
    keyCreator: CargoQuantityPartsKeyCreator,
    width: 67,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'agMultiColumnFilter',
    filterParams: {
        cellRenderer: BlanksFilterRenderer,
        filters: [
            {
                filter: 'quantityFilter',
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    ...defaultSetFilterParams,
                    comparator: CargoQuantityPartFilterComparator,
                    refreshValuesOnOpen: true,
                },
            },
        ],
    },
};
